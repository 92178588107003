window.dataLayer = window.dataLayer || [];
var $doc = $(document);
const CONST = require('plugin_frontend_core/constants');

const selectors = {
    tileWrapper: '.js-product',
    pdCarouselDatalayerListValue: 'input[name="PDProductsCarouselDatalayerListName"]',
    datalayerDataSelectors: [
        'input[name="plpDatalayerData"]',
        'input[name="recommendedProductsDatalayerData"]'
    ]
};


/**
 * Return a unique event ID for dataLayer object
 * @returns {string} unique event ID string
 */
function getUniqueEventID() {
    return new Date().getTime() + '.' + Math.floor(Math.random() * 10000000000);
}


/** Returns list value for the given product ID
 * @param {string} pid Product ID
 * @return {null|string} null or list value for the given product ID
 */
function getListValue(pid) {
    const pdCarouselDatalayerListValue = $(selectors.pdCarouselDatalayerListValue);

    if (pdCarouselDatalayerListValue.length) {
        return $(pdCarouselDatalayerListValue[0]).val();
    }

    let listValue = 'Category';
    selectors.datalayerDataSelectors.forEach(function (selector) {
        const inputs = $(selector);

        if (!inputs.length) {
            return;
        }

        const dataArr = JSON.parse($(inputs[0]).val());

        const datalayerData = dataArr.find(function (data) {
            return data.id === pid;
        });

        if (datalayerData) {
            listValue = datalayerData.list;
        }
    });
    return listValue;
}

function getListValueForPDP(pid, categoryAsDefault) {
    let datalayerPdpListName = window.localStorage.getItem('datalayerPdpListName') || null;

    let list = categoryAsDefault ? 'Category' : null;
    if (datalayerPdpListName) {
        try {
            datalayerPdpListName = JSON.parse(datalayerPdpListName);
            if (datalayerPdpListName[pid]) {
                list = datalayerPdpListName[pid];
            }
        } catch (e) {}
    }

    return list;
}

/**
 * Returns position number of the tile.
 * @param {Object} tileWrapperDOMObject GTM object of the tile
 * @return {number} Integer value
 */
function getTilePosition(tileWrapperDOMObject) {
    const $tileParent = $(tileWrapperDOMObject).parent();
    let tileIndex = 0;

    if ($tileParent.hasClass('js-product-tile__column') || $tileParent.hasClass('product-tile__column')) {
        tileIndex = $tileParent.index();
    } else {
        const pid = $(tileWrapperDOMObject).data('pid');

        $(selectors.tileWrapper + "[data-pid='" + pid + "']").each(function () {
            const $slickSlide = $(this).parents('.slick-slide:not(.slick-cloned)');
            if ($slickSlide.length) {
                tileIndex = $slickSlide.data('slick-index');
            }
        });
    }

    return tileIndex + 1;
}

/**
 * Return a unique event ID for dataLayer object
 * @param {Object} dataLayerObject ID string
 */
function pushEventsInDataLayer(dataLayerObject) {
    const dataLayer = window.dataLayer || [];
    dataLayer.push(dataLayerObject);
}

$doc.ready(function () {
    // Track product impression event on viewport
    $doc.on('productTileImpression', function (e, data) {
        var productImpressionObject = {
            event: 'productImpression',
            eventId: getUniqueEventID(),
            ecommerce: {
                currencyCode: window.sfResources.currency.code,
                impressions: data
            }
        };
        pushEventsInDataLayer(productImpressionObject);
    });

    /**
     * Saved datalayer list name for products to b used with productDetail event.
     *
     * @param {string} listName List name. Allowed: Home, SearchResults, Recommended, RelatedProducts, Category
     * @param {Array<int>} productIds Integer array.
     */
    function setListNameForPDPDetail(listName, productIds) {
        const STORAGE_KEY = 'datalayerPdpListName';
        let datalayerPdpListName = window.localStorage.getItem(STORAGE_KEY) || '{}';
        datalayerPdpListName = JSON.parse(datalayerPdpListName);

        productIds.forEach(function (productId) {
            datalayerPdpListName[productId] = listName;
        });

        window.localStorage.setItem(STORAGE_KEY, JSON.stringify(datalayerPdpListName));
    }

    $doc.on('plp:setListName', function (e, productId, listName) {
        setListNameForPDPDetail(listName, [productId]);
    });

    // Track impression click,
    // Note, don't remove listner without modifying code triggering the 'PLP:gridTileClick'
    // the callback redirects user to the product
    $doc.on('productTileClick', function (e, data) {
        var pid = data.pid;
        let $productTile = $(selectors.tileWrapper + '[data-pid="' + pid + '"]');

        if ($productTile.length === 0) {
            return;
        }

        var dataLayerData = $productTile.find('input[name="productTileDatalayerData"]');
        var productObject = {};
        if (dataLayerData.val() !== undefined && dataLayerData.val() !== '') {
            productObject = JSON.parse(dataLayerData.val());
        } else {
            productObject = {
                name: dataLayerData.attr('data-name'),
                id: dataLayerData.attr('data-id'),
                price: dataLayerData.attr('data-price'),
                brand: dataLayerData.attr('data-brand'),
                category: dataLayerData.attr('data-category'),
                variant: dataLayerData.attr('data-variant')
            };
        }
        productObject.position = getTilePosition($productTile);

        var productClickObj = {
            event: 'productClick',
            eventID: getUniqueEventID(),
            ecommerce: {
                click: {
                    actionField: {
                        list: data.list
                    },
                    products: [productObject]
                }
            }
        };
        window.dataLayer.push(productClickObj);
    });

    // Track Add to cart
    $doc.on('product:afterAddToCart', function (e, data) {
        const addToCartObj = data.addToCartObj;
        let listValue = getListValueForPDP(data.productID, false);
        if (!listValue && $('.js-product-detail').length && data.vgProductID) {
            listValue = getListValueForPDP(data.vgProductID);
        } else if (!listValue) {
            const el = $(this).find("[data-pid='" + addToCartObj.ecommerce.add.products[0].id + "']");

            if (el.length > 0) {
                listValue = getListValue(el[0].getAttribute('data-vg-pid'));
            }
        }

        if (addToCartObj && typeof addToCartObj === 'object') {
            addToCartObj.ecommerce.add.actionField = {
                list: listValue
            };
            window.dataLayer.push(addToCartObj);
        }
    });

    // Track checkout step, going to next step
    $doc.on('checkout:changeCheckoutStep', function (e, data) {
        var checkoutObj = data.checkoutObj;
        if (checkoutObj && typeof checkoutObj === 'object') {
            window.dataLayer.push(checkoutObj);
        }
    });

    // Track checkout step, going to next step
    $doc.on(CONST.events.checkoutOption, function (e, data) {
        const eventObject = {
            event: 'checkoutOption',
            eventID: getUniqueEventID(),
            ecommerce: {
                checkout_option: {
                    actionField: { step: data.step, option: data.option }
                }
            }
        };
        window.dataLayer.push(eventObject);
    });

    // Track checkout step, edit step
    $doc.on('checkout:editCheckoutStep', function (e, data) {
        var currentStage = data.currentStage;
        var currentStageName = data.currentStageName;
        for (var i = 0; i < window.dataLayer.length; i++) {
            var item = window.dataLayer[i];
            if (item.event && item.event === 'checkout') {
                var checkoutObj = item;
                checkoutObj.ecommerce.checkout.actionField.option = currentStageName;
                checkoutObj.ecommerce.checkout.actionField.step = currentStage;
                window.dataLayer.push(checkoutObj);
                break;
            }
        }
    });

    //Track promotion click
    $(CONST.selectors.promotion.tile).on(CONST.events.click, function (e, data) {
        let row = $(CONST.selectors.content.tile);
        let index;
        for (index = 0; index < row.length; index++) {
            if (row[index] === $(this)[0]) {
                break;
            }
        }
        window.dataLayer.push({
            event: CONST.events.promotionClick,
            eventID: getUniqueEventID(),
            ecommerce: {
                promoClick: {
                    promotions: [
                        {
                            id: $(this).attr('data-promotion-id'),
                            name: $(this).attr('data-promotion-name'),
                            creative: $(this).attr('data-promotion-creative'),
                            position: index < row.length ? index + 1 : 'unkown'
                        }]
                }
            }
        });
    });
});
